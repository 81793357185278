import React from "react"
import Website from "../../templates/Website"
import ComiquestContent from "../../components/ComiquestContent"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <Website>
      <ComiquestContent />
    </Website>
  )
}

export default MemoQuest
