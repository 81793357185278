import React from "react"
import data from "../../content/comiquest-data.json"
import GameScreenshots from "./GameScreenshots"
import StoreBar from "./StoreBar"
import GamePresentationBox from "./GamePresentationBox"
import GameFeatures from "./GameFeatures"
import SEO from "./SEO"
import ContactInfo from "./ContactInfo"

interface Props {}

const ComiquestContent: React.FC<Props> = () => {
  return (
    <GamePresentationBox data={data}>
      <SEO description={data.presentation.text} title={data.gameName} />
      <StoreBar gameId={data.gameId} stores={data.stores} />
      <GameScreenshots data={data} />
      <GameFeatures data={data} />
      <ContactInfo />
    </GamePresentationBox>
  )
}

export default ComiquestContent
